import React from 'react'
import Poster from '../Components/Poster'

export default function Home() {
  return (
    <div className='h-screen w-screen'>
      <Poster/>
    </div>
  )
}
